<script>
  import { get, call } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import SubscribeMarketHero from '@/components/subscribe/SubscribeMarketHero'
  import SubscribeMarketExitModal from '@/components/subscribe/SubscribeMarketExitModal'
  import ProductCard from '@/components/marketplace/ProductCard'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if (
        ((await store.dispatch('account/loggedInAsync')) === false ||
          !store.get('subscribe/subscriptionCreated')) &&
        !store.get('account/isAdmin')
      ) {
        return redirect({ name: 'SubscribePlan' })
      }
    },
    ['loggedIn']
  )

  export default {
    components: {
      SubscribeMarketHero,
      SubscribeMarketExitModal,
      ProductCard,
    },
    mixins: [guardMixin],
    data() {
      return {
        openInfoModal: false,
        openExitModal: false,
        infoModalFeaturesList: [
          'Save up to 70%+ with exclusive product discounts',
          'Shop members’ favorite goods with this limited&#8209;time access',
          'Discover more ethically and sustainably made products you’ll love!',
        ],
      }
    },
    computed: {
      ...get('general', ['navigating']),
      ...get('account', ['loggedIn']),
      ...get('subscribe', ['subscriptionCreated']),
      ...get('upsell', [
        'subtotal',
        'retailTotal',
        'cartQuantity',
        'upsellProducts',
        'isProductInCart',
      ]),
    },
    created() {
      this.fetchUpsellProducts()
      this.clearRemovedLineItems()
    },
    methods: {
      ...call('upsell', ['fetchUpsellProducts', 'addToCart', 'clearRemovedLineItems']),
      next() {
        this.$navigate({ name: 'SubscribeMarketCheckout' })
      },
      viewDetails(product) {
        this.$navigate({
          name: 'SubscribeMarketProduct',
          params: {
            productId: product.id,
            type: 'upsell',
          },
        })
      },
    },
  }
</script>

<template>
  <section class="pb-35 sm:pb-45">
    <SubscribeMarketHero @openModal="openInfoModal = true" />
    <div class="max-w-240 mx-auto px-4 sm:px-9">
      <div class="mt-15 sm:mt-20">
        <div v-if="!upsellProducts || upsellProducts.length === 0" class="h-40">
          <BaseSpinner overlay="absolute" />
        </div>
        <BaseCardGrid v-else min-card-width="sm">
          <ProductCard
            v-for="product in upsellProducts"
            :key="product.id"
            :product="product"
            type="upsell"
            :has-slider="true"
            :is-in-cart="isProductInCart(product.id)"
            @select="addToCart({ variantId: product.defaultVariant.id })"
            @viewDetails="viewDetails($event)"
            @clearRemovedLineItems="clearRemovedLineItems"
          />
        </BaseCardGrid>
      </div>
    </div>

    <BaseContainer max-width="sm" class="mt-15 sm:mt-25">
      <BaseButton outline-only @click="openExitModal = true">
        No thanks, visit my account
      </BaseButton>
    </BaseContainer>

    <Portal to="fixedBottom">
      <BaseTransitionFixedBottom>
        <div
          v-if="!navigating && cartQuantity > 0"
          class="py-4 border-t border-gray-active bg-white"
        >
          <BaseContainer max-width="lg">
            <div class="flex justify-between items-center">
              <div class="mr-6">
                <div class="font-heading font-medium text-2xl leading-none">
                  <span class="mr-3">{{ $formatPrice(subtotal) }}</span
                  ><s v-if="retailTotal && retailTotal > subtotal" class="text-gray-light">{{
                    $formatPrice(retailTotal)
                  }}</s>
                </div>
                <div class="text-2xs">
                  {{ cartQuantity }} item{{ cartQuantity > 1 ? 's' : '' }} added
                </div>
              </div>
              <div class="flex-1 max-w-60">
                <BaseButton @click="next">Check Out</BaseButton>
              </div>
            </div>
          </BaseContainer>
        </div>
      </BaseTransitionFixedBottom>
    </Portal>

    <Portal to="modal">
      <BaseFeaturesModal
        :open="openInfoModal"
        :feature-list="infoModalFeaturesList"
        @dismiss="openInfoModal = false"
      >
        <template v-slot:kicker> ONE-TIME-ONLY SHOP </template>
        <template v-slot:header> New Members Market </template>
        <template v-slot:dismissCta> Okay </template>
      </BaseFeaturesModal>
      <SubscribeMarketExitModal :open="openExitModal" @dismiss="openExitModal = false" />
    </Portal>

    <Portal to="productModal">
      <RouterView />
    </Portal>
  </section>
</template>
