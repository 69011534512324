<script>
  import { get } from 'vuex-pathify'
  import MarketShippingProgress from '@/components/marketplace/MarketShippingProgress'
  import WidgetAmount from '@/components/marketplace/WidgetAmount'
  import screen from '@/helpers/screen'

  export default {
    components: {
      MarketShippingProgress,
      WidgetAmount,
    },
    computed: {
      ...get('upsell', ['cartSavings']),
      screen,
    },
  }
</script>

<template>
  <section class="lg:border-b-2 lg:border-black">
    <div class="flex flex-col-reverse lg:flex-row">
      <BaseImage
        :src="`/static/subscribe/subscribe-market-hero-${screen.lg ? 'desktop' : 'mobile'}.gif`"
        alt=""
        responsive="lg"
        :sizes="{ default: '100vw', lg: '40vw' }"
        background-transparent
        class="w-full lg:max-w-82 xl:max-w-105 border-t border-black lg:border-none"
      />
      <div class="flex flex-col justify-between w-full">
        <div class="h-full flex flex-col items-center justify-center w-full">
          <span class="mt-9 mb-2 lg:mt-0 xl:mb-0 font-heading leading-none text-2xl xl:text-4xl">
            Just for You: An Exclusive
          </span>
          <h1
            class="mb-6 lg:mb-3 font-heading font-semibold leading-heading text-48px xl:text-66px text-center"
          >
            New Members <br v-if="!screen.lg" />
            Market
          </h1>
          <!-- Widgets -->
          <div class="flex flex-row mb-8 lg:mb-0">
            <div class="grid grid-cols-3">
              <!-- Free shipping progress bar -->
              <div class="widgets mr-2 xl:mr-4 col-span-2">
                <div class="widgets-subheading">Get free shipping!</div>
                <MarketShippingProgress type="upsell" class="shipping-progress" />
              </div>
              <!-- Savings -->
              <div class="widgets">
                <div class="widgets-subheading">Your savings:</div>
                <WidgetAmount :value="cartSavings" class="savings-widget" />
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="screen.lg"
          class="w-full py-22px bg-primary text-sm text-dawn text-center"
          @click="$emit('openModal')"
        >
          This market is only available now, so take advantage and shop now!
          <BaseIcon :size="3" class="align-middle inline-block mb-2px ml-1">
            <IconChevronRightThick />
          </BaseIcon>
        </button>
      </div>
    </div>
    <button
      v-if="!screen.lg"
      class="flex items-center justify-between w-full py-18px bg-primary text-sm text-dawn text-center"
      @click="$emit('openModal')"
    >
      <div class="max-w-82 md:max-w-none mx-auto">
        <div class="max-w-63 md:max-w-none mx-auto">
          This market is only available now, so take advantage and shop now!
        </div>
      </div>
      <BaseIcon :size="3" class="mr-15px">
        <IconChevronRightThick />
      </BaseIcon>
    </button>
  </section>
</template>

<style lang="postcss">
  .widgets {
    @apply flex flex-col items-center bg-dawn py-10px px-1;

    @screen 2xs {
      @apply px-3;
    }
    @screen xs {
      @apply px-4;
    }
  }

  .widgets-subheading {
    @apply mb-2 font-semibold text-3xs;

    @screen xl {
      @apply text-sm;
    }
  }

  .savings-widget {
    & > {
      .savings-amount {
        @screen 2xs {
          @apply text-2xl;
        }
        @screen xs {
          @apply text-3xl;
        }
      }
    }
  }

  .shipping-progress {
    & > {
      .progress-amount {
        @screen 2xs {
          @apply text-2xl;
        }
        @screen xs {
          @apply text-3xl;
        }
      }
    }
  }
</style>
